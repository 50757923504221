import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './styles/main.scss';

// pages
import Home from './pages/Home';
import Login from './pages/Login'; // Assuming you have a Login component
import Unknown from './pages/Unknown'; // Assuming you have an Unknown component
import DepositBooster from './pages/DepositBooster';
import RoowardsPage from './pages/RoowardsPage';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/modal=auth&tab=login" element={<Login />} />
        <Route path="/promotion=deposit-booster" element={<DepositBooster />} />
        <Route path="/roowards-bonus" element={<RoowardsPage />} />
        <Route path="*" element={<Unknown />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);