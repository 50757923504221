import { useState } from 'react';

//services
import localStorageService from '../services/local_storage';

//svg
import { ReactComponent as BellSvg } from '../svg/bell.svg';
// import { ReactComponent as UserSvg } from '../svg/user.svg';

import { ReactComponent as CashierSvg } from '../svg/cashier.svg';
import { ReactComponent as SettingsSvg } from '../svg/settings.svg';
import { ReactComponent as ProfileSvg } from '../svg/profile.svg';
import { ReactComponent as HistorySvg } from '../svg/history.svg';
import { ReactComponent as ReferSvg } from '../svg/refer.svg';
import { ReactComponent as HelpSvg } from '../svg/help-center.svg';
import { ReactComponent as LiveSupportSvg } from '../svg/live-support.svg';
import { ReactComponent as LogoutSvg } from '../svg/logout.svg';
import Rank from './Rank';


function TopNavUser({ loggedinUser }) {
    const [isOpenUserMenu, setIsOpenUserMenu] = useState(false)

    function logout() {
        localStorageService.deleteFromLocalStorage('user')
        localStorageService.deleteFromLocalStorage('rank')
        window.location.href = '/';
    }
    
    return (
        <div className="top-nav-user-container">
            <div className="clickable bell">
                <BellSvg className="svg-icon" />
            </div>
            <div className='user-container'>
                <div className="clickable clickable-rank" onClick={() => { setIsOpenUserMenu(true) }}>
                    {/* <UserSvg className="svg-icon" /> */}
                    <Rank isOpenUserMenu={isOpenUserMenu} />
                </div>
                {isOpenUserMenu && <div className='exit-bg' onClick={() => { setIsOpenUserMenu(false) }}></div>}
                {isOpenUserMenu && <div className='user-menu'>
                    <div className='user-name'>
                        Hey, <span>{loggedinUser}</span>
                    </div>

                    <div>
                        <div className='menu-item first last bell'>
                            <BellSvg className="svg-icon" />
                            <span>Notifications</span>
                        </div>
                    </div>

                    <div>
                        <a href='https://roobet.com/?modal=cashier' className='menu-item first'>
                            <CashierSvg className="svg-icon" />
                            <span>Cashier</span>
                        </a>
                        <a href='https://roobet.com/account-settings?page=general' className='menu-item'>
                            <SettingsSvg className="svg-icon" />
                            <span>Account Settings</span>
                        </a>
                        <a href={`https://roobet.com/?page=general&modal=profile&user=${loggedinUser}`} className='menu-item'>
                            <ProfileSvg className="svg-icon" />
                            <span>Profile</span>
                        </a>
                        <a href='https://roobet.com/?modal=history&tab=deposits' className='menu-item'>
                            <HistorySvg className="svg-icon" />
                            <span>History</span>
                        </a>
                        <a href='https://roobet.com/?modal=affiliate&tab=referFriends' className='menu-item last'>
                            <ReferSvg className="svg-icon" />
                            <span>Refer & Earn</span>
                        </a>
                    </div>

                    <div>
                        <a href='https://help.roobet.com/' className='menu-item first'>
                            <HelpSvg className="svg-icon" />
                            <span>Help Center</span>
                        </a>
                        <div className='menu-item last'>
                            <LiveSupportSvg className="svg-icon" />
                            <span>Live Support</span>
                        </div>

                    </div>

                    <div>
                        <div className='menu-item first last'
                            onClick={logout}
                        >
                            <LogoutSvg className="svg-icon" />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div >
    )
}

export default TopNavUser;