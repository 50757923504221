import { useEffect, useState, useRef } from 'react';

//svg
import { ReactComponent as TwitterSvg } from '../svg/twitter.svg';
import { ReactComponent as FacebookSvg } from '../svg/facebook.svg';
import { ReactComponent as InstagramSvg } from '../svg/instagram.svg';
import { ReactComponent as TwitchSvg } from '../svg/twitch.svg';
import { ReactComponent as TelegramSvg } from '../svg/telegram.svg';
import { ReactComponent as SpotifySvg } from '../svg/spotify.svg';
import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';



function Links() {
    const [isScreenLargerThan800, setIsScreenLargerThan800] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const dropdownRefs = useRef([]);

    //Check screen size open nav for 1200px
    useEffect(() => {
        const handleResize = () => {
            setIsScreenLargerThan800(window.innerWidth >= 800);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const headers = [
        {
            title: `Casino`,
            items: [{ name: "Roobet Originals", url: "https://roobet.com/casino?category=roobet-games" },
            { name: "Popular Games", url: "https://roobet.com/casino?category=popular" },
            { name: "Slots", url: "https://roobet.com/casino?category=slots" },
            { name: "Bonus Buys", url: "https://roobet.com/tag/bonus-buys" },
            { name: "Live Casino", url: "https://roobet.com/casino?category=livecasino" },
            { name: "Game Shows", url: "https://roobet.com/casino?category=gameshows" },
            { name: "Roulette", url: "https://roobet.com/tag/roulette" },
            { name: "Blackjack", url: "https://roobet.com/tag/blackjack" },
            { name: "Baccarat", url: "https://roobet.com/tag/baccarat" },
            { name: "Fair", url: "https://roobet.com/fair" }]
        },
        {
            title: `Sportsbook`,
            items: [{ name: "Home", url: "https://roobet.com/sports" },
            { name: "Soccer", url: "https://roobet.com/sports?bt-path=%2Fsoccer-1" },
            { name: "Basketball", url: "https://roobet.com/sports?bt-path=%2Fbasketball-2" },
            { name: "Tennis", url: "https://roobet.com/sports?bt-path=%2Ftennis-5" },
            { name: "Counter-Strike", url: "https://roobet.com/sports?bt-path=%2Fcounter-strike-109" },
            { name: "FIFA", url: "https://roobet.com/sports?bt-path=%2Ffifa-300" },
            { name: "Baseball", url: "https://roobet.com/sports?bt-path=%2Fbaseball-3" },
            { name: "Ice-Hockey", url: "https://roobet.com/sports?bt-path=%2Fice-hockey-4" },
            { name: "Cricket", url: "https://roobet.com/sports?bt-path=%2Fcricket-21" },
            { name: "MMA", url: "https://roobet.com/sports?bt-path=%2Fmma-117" },
            { name: "Table Tennis", url: "https://roobet.com/sports?bt-path=%2Ftable-tennis-20" }]
        },
        {
            title: `Policies`,
            items: [{ name: "Terms of Service", url: "https://roobet.com/terms-and-conditions" },
            { name: "Privacy Policy", url: "https://roobet.com/privacy-policy" },
            { name: "Bonus & Promotion", url: "https://roobet.com/bonus-and-promotion-policy" },
            { name: "Sportsbook", url: "https://roobet.com/sportsbook-policy" },
            { name: "Game Policy", url: "https://roobet.com/game-policy" },
            { name: "KYC-AML-CFT Policy", url: "https://roobet.com/kyc-aml-policy" },
            { name: "Responsible Gambling", url: "https://roobet.com/responsible-gambling" }]
        },
        {
            title: `Promos`,
            items: [{ name: "VIP Club", url: "https://roobet.com/vip" },
            { name: "Promotions", url: "https://promotions.roobet.com" },
            { name: "Redeem a Promo", url: "https://roobet.com/?modal=redeem" },
            { name: "Affiliate Program", url: "https://roobetaffiliates.com/" }]
        },
        {
            title: `Support`,
            items: [{ name: "About Us", url: "https://roobet.com/about-us" },
            { name: "Live Support", url: "https://roobet.com/" },
            { name: "Help Center", url: "https://help.roobet.com/" },
            { name: "Roobet Official Mirrors", url: "https://playroobet.com/" },
            { name: "Roo Responsibility", url: "https://rooresponsibly.com/" },
            { name: "Vulnerability Disclosure", url: "https://bugcrowd.com/roobet-vdp" }]
        },
    ];

    const ComunityHtml = () => {
        return (
            <div className="footer-links-col">
                <div className="footer-links-col-title">
                    Community
                </div>
                <div className="footer-links-col-links social">
                    <a href="https://twitter.com/roobet">
                        <TwitterSvg className="svg-icon" />
                    </a>
                    <a href="https://www.facebook.com/RoobetCom/">
                        <FacebookSvg className="svg-icon" />
                    </a>
                    <a href="https://www.instagram.com/roobet/">
                        <InstagramSvg className="svg-icon" />
                    </a>
                    <a href="https://www.twitch.tv/roobetcom">
                        <TwitchSvg className="svg-icon" />
                    </a>
                    <a href="https://t.me/roobet_com">
                        <TelegramSvg className="svg-icon" />
                    </a>
                    <a href="https://open.spotify.com/user/31fcuitp5bvrxgp364546htxvt7u?si=hS4Tr3vfTUm_CXmvscFHPQ&nd=1">
                        <SpotifySvg className="svg-icon" />
                    </a>
                </div>
            </div>
        )
    }
    const toggleMenu = (index) => {
        setOpenMenu(openMenu === index ? null : index);
    };

    const setHeight = (index) => {
        const dropdown = dropdownRefs.current[index];
        if (dropdown) {
            const height = dropdown.scrollHeight;
            dropdown.style.maxHeight = openMenu === index ? `${height}px` : '0px';
        }
    };

    if (!isScreenLargerThan800) {
        return (<>
            <div className="dropdown-menu">
                {headers.map((header, index) => (
                    <div key={index} className={`dropdown-header ${openMenu === index ? 'active' : ''}`}>
                        <div className="header-title" onClick={() => toggleMenu(index)}>
                            <p>{header.title}</p>
                            <ChevronDownSvg className="svg-icon" />
                        </div>
                        <div
                            ref={(el) => (dropdownRefs.current[index] = el)}
                            className="dropdown-items"
                            onAnimationEnd={() => setHeight(index)}
                        >
                            {header.items.map((item, i) => (
                                <a href={item.url} key={i}>{item.name}</a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <ComunityHtml />
        </>
        )
    }

    return (
        <div className="footer-links-container">
            {headers.map((header, index) => (
                <div className="footer-links-col" key={index}>
                    <div className="footer-links-col-title">{header.title}</div>
                    <div className="footer-links-col-links">
                        {header.items.map((item, i) => (
                            <a href={item.url} key={i}>{item.name}</a>
                        ))}
                    </div>
                </div>
            ))}
            <ComunityHtml />
        </div>
    )
}

export default Links;