import Skrill from "../images/partners/skrill.avif";
import AstroPay from "../images/partners/astroPay.png";
import Interac from "../images/partners/interac.png";
import Pix from "../images/partners/pix.png";
import WebPay from "../images/partners/wp.avif";
import Upi from "../images/partners/upi.avif";
import Ef from "../images/partners/ef.avif";


function Partners() {

    return (
        <div className="partners">
            <h2>Partners</h2>
            <div><img src={Skrill} alt="Partners" /></div>
            <div><img src={AstroPay} alt="Partners" /></div>
            <div><img className="interact-img" src={Interac} alt="Partners" /></div>
            <div><img src={Pix} alt="Partners" /></div>
            <div><img src={WebPay} alt="Partners" /></div>
            <div><img src={Upi} alt="Partners" /></div>
            <div><img src={Ef} alt="Partners" /></div>
        </div >
    )
}

export default Partners;