import { useState, useEffect } from "react";

//services
import localStorageService from '../services/local_storage';

//NAV TOP SVG
import { ReactComponent as CasinoSvg } from '../svg/casino.svg';
import { ReactComponent as SportsSvg } from '../svg/sports.svg';

//NAV GRPOUP SVG
import { ReactComponent as PromotionsSvg } from '../svg/promotions.svg';
import { ReactComponent as ReferSvg } from '../svg/refer.svg';
import { ReactComponent as RedeemSvg } from '../svg/redeem.svg';
import { ReactComponent as FreePlaySvg } from '../svg/free-play.svg';

//NAV BOTTOM SVG
import { ReactComponent as VipSvg } from '../svg/vip.svg';
import { ReactComponent as LiveSupportSvg } from '../svg/live-support.svg';
import globImg from '../svg/globus.svg';

//CASINO GAMES SVG
import { ReactComponent as GamesSvg } from '../svg/games.svg';
import { ReactComponent as PopularSvg } from '../svg/popular.svg';
import { ReactComponent as NewGamesSvg } from '../svg/new-games.svg';
import { ReactComponent as SlotsSvg } from '../svg/slots.svg';
import { ReactComponent as BonusSvg } from '../svg/bonus.svg';
import { ReactComponent as LiveCasinoSvg } from '../svg/live-casino.svg';
import { ReactComponent as GamesShowsSvg } from '../svg/games-shows.svg';
import { ReactComponent as RouletteSvg } from '../svg/roulette.svg';
import { ReactComponent as BlackjackSvg } from '../svg/blackjack.svg';
import { ReactComponent as BaccaratSvg } from '../svg/baccarat.svg';

//SPORTS GAMES SVG
import { ReactComponent as SoccerSvg } from '../svg/soccer.svg';
import { ReactComponent as BasketballSvg } from '../svg/basketball.svg';
import { ReactComponent as TennisSvg } from '../svg/tennis.svg';
import { ReactComponent as CounterStrikeSvg } from '../svg/counter-strike.svg';
import { ReactComponent as FifaSvg } from '../svg/fifa.svg';
import { ReactComponent as BaseballSvg } from '../svg/baseball.svg';
import { ReactComponent as IceHockeySvg } from '../svg/ice-hockey.svg';
import { ReactComponent as CricketSvg } from '../svg/cricket.svg';
import { ReactComponent as MmaSvg } from '../svg/mma.svg';
import { ReactComponent as TableTennisSvg } from '../svg/table-tennis.svg';

//MORE SVG
import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';
import { ReactComponent as RewardsSvg } from '../svg/rewards.svg';


//BET COUNTER CMP
import BetCounter from './BetCounter';

function NavMenu() {
    const [isCasinoDropDownOpened, setIsCasinoDropDownOpened] = useState(false)
    const [isSportsDropDownOpened, setIsSportsDropDownOpened] = useState(true)
    const [loggedinUser, setLoggedinUser] = useState(null)
    const activeLang = 'English'

    useEffect(() => {
        const user = localStorageService.readFromLocalStorage("user")
        if (user) {
            setLoggedinUser(user)
        }
    }, [])

    const toggleCasinoDropDown = () => {
        setIsCasinoDropDownOpened(!isCasinoDropDownOpened);
        if (!isCasinoDropDownOpened && isSportsDropDownOpened) {
            setIsSportsDropDownOpened(false);
        }
    };

    const toggleSportsDropDown = () => {
        setIsSportsDropDownOpened(!isSportsDropDownOpened);
        if (!isSportsDropDownOpened && isCasinoDropDownOpened) {
            setIsCasinoDropDownOpened(false);
        }
    };

    return (
        <div className="nav-menu">
            <div className="navbar">
                <div className="home-side-nav-item-opened">
                    <div
                        onClick={toggleCasinoDropDown}
                        className={`home-side-nav-item-opened-btn ${isCasinoDropDownOpened ? "active" : ""}`}
                    >
                        <div>
                            <CasinoSvg className="svg-icon" />
                            <p>Casino</p>
                        </div>
                        <div className='chevron-container'>
                            <ChevronDownSvg className="svg-icon" />
                        </div>
                    </div>
                    <div className={`falldown-menu ${isCasinoDropDownOpened ? "active" : ""}`}>
                        <a href="https://roobet.com/casino?category=roobet-games">
                            <GamesSvg className="svg-icon" />
                            <p>Roobet Games</p>
                        </a>
                        <a href='https://roobet.com/casino?category=popular'>
                            <PopularSvg className="svg-icon" />
                            <p>Popular</p>
                        </a>
                        <a href='https://roobet.com/tag/new-games'>
                            <NewGamesSvg className="svg-icon" />
                            <p>New Games</p>
                        </a>
                        <a href='https://roobet.com/casino?category=slots'>
                            <SlotsSvg className="svg-icon" />
                            <p>Slots</p>
                        </a>
                        <a href='https://roobet.com/tag/bonus-buys'>
                            <BonusSvg className="svg-icon" />
                            <p>Bonus Buys</p>
                        </a>
                        <a href='https://roobet.com/casino?category=livecasino'>
                            <LiveCasinoSvg className="svg-icon" />
                            <p>Live Casino</p>
                        </a>
                        <a href='https://roobet.com/casino?category=gameshows'>
                            <GamesShowsSvg className="svg-icon" />
                            <p>Games Shows</p>
                        </a>
                        <a href='https://roobet.com/tag/roulette'>
                            <RouletteSvg className="svg-icon" />
                            <p>Roulette</p>
                        </a>
                        <a href='https://roobet.com/tag/blackjack'>
                            <BlackjackSvg className="svg-icon" />
                            <p>Blackjack</p>
                        </a>
                        <a href='https://roobet.com/tag/baccarat' className='last'>
                            <BaccaratSvg className="svg-icon" />
                            <p>Baccarat</p>
                        </a>
                    </div>
                </div>

                <div className="home-side-nav-item-opened">
                    <div
                        onClick={toggleSportsDropDown}
                        className={`home-side-nav-item-opened-btn ${isSportsDropDownOpened ? "active" : ""}`}
                    >
                        <div>
                            <SportsSvg className="svg-icon" />
                            <p>Sportsbook</p>
                        </div>
                        <div className='chevron-container'>
                            <ChevronDownSvg className="svg-icon" />
                        </div>
                    </div>
                    <div className={`falldown-menu ${isSportsDropDownOpened ? "active" : ""}`}>
                        <a href="https://roobet.com/sports?bt-path=%2Fsoccer-1">
                            <SoccerSvg className="svg-icon" />
                            <p>Soccer</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fbasketball-2">
                            <BasketballSvg className="svg-icon" />
                            <p>Basketball</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Ftennis-5">
                            <TennisSvg className="svg-icon" />
                            <p>Tennis</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fcounter-strike-109">
                            <CounterStrikeSvg className="svg-icon" />
                            <p>Counter-Strike</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Ffifa-300">
                            <FifaSvg className="svg-icon" />
                            <p>FIFA</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fbaseball-3">
                            <BaseballSvg className="svg-icon" />
                            <p>Baseball</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fice-hockey-4">
                            <IceHockeySvg className="svg-icon" />
                            <p>Ice-Hockey</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fcricket-21">
                            <CricketSvg className="svg-icon" />
                            <p>Cricket</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Fmma-117">
                            <MmaSvg className="svg-icon" />
                            <p>MMA</p>
                        </a>
                        <a href="https://roobet.com/sports?bt-path=%2Ftable-tennis-20" className='last'>
                            <TableTennisSvg className="svg-icon" />
                            <p>Table Tennis</p>
                        </a>
                    </div>
                </div>

                {loggedinUser && <a href='https://roobet.com/rewards' className="home-side-nav-item bottom opened">
                    <RewardsSvg className="svg-icon" />
                    <p>Rewards</p>
                </a>}

                <div className='nav-bar-group-container opened'>
                    <a href='https://promotions.roobet.com/' className="home-side-nav-item first">
                        <PromotionsSvg className="svg-icon" />
                        <p>Promotions</p>
                    </a>
                    <a href='https://roobet.com/?modal=affiliate&tab=referFriends' className="home-side-nav-item">
                        <ReferSvg className="svg-icon" />
                        <p>Refer & Earn</p>
                    </a>
                    <a href='https://roobet.com/?modal=redeem' className="home-side-nav-item">
                        <RedeemSvg className="svg-icon" />
                        <p>Redeem</p>
                    </a>
                    <a href='https://roobet.fun/' className="home-side-nav-item last">
                        <FreePlaySvg className="svg-icon" />
                        <p>Free Play</p>
                    </a>
                </div>

                <a href='https://roobet.com/vip' className="home-side-nav-item bottom opened">
                    <VipSvg className="svg-icon" />
                    <p>VIP Club</p>
                </a>
                <div className="home-side-nav-item bottom opened">
                    <LiveSupportSvg className="svg-icon" />
                    <p>Live Support</p>
                </div>

                <div className="home-side-nav-item bottom opened lang">
                    <div>
                        <img src={globImg} alt="English" />
                        <p>{activeLang}</p>
                    </div>
                    <div className='chevron-container'>
                        <ChevronDownSvg className="svg-icon" />
                    </div>
                </div>

                <BetCounter />

            </div >
        </div>
    )
}

export default NavMenu;