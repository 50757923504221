import React, { useEffect, useRef, useState } from 'react';

// Games
import MissionUncrossable from "../images/games/mission-uncrossable.avif";
import BlackjackVIPPlatinum from "../images/games/blackjack-VIP-platinum.avif";
import CashCrew from "../images/games/cash-crew.avif";
import Crash from "../images/games/crash.avif";
import RavenRising from "../images/games/raven-rising.avif";
import RetroSweets from "../images/games/retro-sweets.avif";
import SweetBonanza from "../images/games/sweet-bonanza.avif";
import SugarRush1000 from "../images/games/sugar-rush1000.avif";
import RoobetFirstPerson from "../images/games/roobet-first-person.avif";
import ReleaseTheBison from "../images/games/release-the-bison.avif";
import AztecTwist from "../images/games/aztec-twist.avif";
import WantedDeadOrAWild from "../images/games/wanted-dead-or-a-wild.avif";
import InfiniteBlackjack from "../images/games/infinite-blackjack.avif";
import GatesOfOlympus from "../images/games/gates-of-olympus.avif";
import FruitParty from "../images/games/fruit-party.avif";
import Rooolette from "../images/games/rooolette.avif";
import Blackjack72Ruby from "../images/games/blackjack72-ruby.avif";

// SVG
// import { ReactComponent as HiddenSvg } from '../svg/hidden-user.svg';

// Coins SVG
import { ReactComponent as BitcoinSvg } from '../svg/currencies/bitcoin.svg';
import { ReactComponent as UsdtSvg } from '../svg/currencies/usdt.svg';
import { ReactComponent as UsdcSvg } from '../svg/currencies/usdc.svg';
import { ReactComponent as EthSvg } from '../svg/currencies/eth.svg';
import { ReactComponent as XrpSvg } from '../svg/currencies/xrp.svg';
import { ReactComponent as TrxSvg } from '../svg/currencies/trx.svg';
import { ReactComponent as LtcSvg } from '../svg/currencies/ltc.svg';
import { ReactComponent as DogeSvg } from '../svg/currencies/doge.svg';
import { ReactComponent as CashSvg } from '../svg/currencies/cash.svg';

// rank imgs
import beginnerImg from "../images/rank/beginner.png";
import silver1Img from "../images/rank/silver1.png";
import silver2Img from "../images/rank/silver2.png";
import silver3Img from "../images/rank/silver3.png";
import silver4Img from "../images/rank/silver4.png";
import gold1Img from "../images/rank/gold1.png";
import gold2Img from "../images/rank/gold2.png";
import gold3Img from "../images/rank/gold3.png";
import gold4Img from "../images/rank/gold4.png";
import emerald1Img from "../images/rank/emerald1.png";
import emerald2Img from "../images/rank/emerald2.png";
import emerald3Img from "../images/rank/emerald3.png";
import ruby1Img from "../images/rank/ruby1.png";
import ruby2Img from "../images/rank/ruby2.png";
import ruby3Img from "../images/rank/ruby3.png";
import diamond1Img from "../images/rank/diamond1.png";
import diamond2Img from "../images/rank/diamond2.png";
import diamond3Img from "../images/rank/diamond3.png";

const HistoryTable = () => {
    const [activeTab, setActiveTab] = useState('All');
    const [isMoreThan1390px, setIsMoreThan1200px] = useState(null);
    const [isMoreThan600px, setIsMoreThan600px] = useState(null);

    useEffect(() => {
        // Function to update state based on screen width
        const updateScreenWidth = () => {
            setIsMoreThan1200px(window.innerWidth > 1390);
            setIsMoreThan600px(window.innerWidth > 600);
        };

        // Initial call to set state based on initial screen width
        updateScreenWidth();

        // Event listener to update state when screen width changes
        window.addEventListener('resize', updateScreenWidth);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, [])

    const data = [
        { game: "Mission Uncrossable", user: "MoneyHungry", wager: 5.30, coin: "crypto", payout: 0.00, rank: 14 },
        { game: "Blackjack VIP Platinum", user: "sheriff35", wager: 150.00, coin: "eth", payout: 0.00, rank: 5 },
        { game: "Cash Crew", user: "drew", wager: 750.00, coin: "usdt", payout: 180.00, rank: 11 },
        { game: "Blackjack VIP Platinum", user: "hidden", wager: 89.25, coin: "eth", payout: 1324.89, rank: 1 },
        { game: "Cash Crew", user: "drew", wager: 750.00, coin: "usdt", payout: 890.00, rank: 16 },
        { game: "Crash", user: "Jojojo10202", wager: 20.00, coin: "xrp", payout: 158.68, rank: 9 },
        { game: "Raven Rising", user: "Steve", wager: 4.00, coin: "crypto", payout: 2092.60, rank: 7 },
        { game: "Retro Sweets", user: "Ddurz", wager: 400.00, coin: "crypto", payout: 297.00, rank: 4 },
        { game: "Sweet Bonanza", user: "Dunmorejake123", wager: 600.00, coin: "cash", payout: 0.00, rank: 12 },
        { game: "Cash Crew", user: "drew", wager: 1800.00, coin: "usdt", payout: 1056.59, rank: 3 },
        { game: "Sweet Bonanza", user: "hidden", wager: 18.47, coin: "cash", payout: 102.36, rank: 15 },
        { game: "Retro Sweets", user: "hidden", wager: 721.68, coin: "usdc", payout: 456.77, rank: 2 },
        { game: "Sugar Rush 1000", user: "Foka69", wager: 2400.00, coin: "usdt", payout: 0.00, rank: 10 },
        { game: "Roobet First Person ...", user: "rescate", wager: 90.00, coin: "doge", payout: 0.00, rank: 8 },
        { game: "Cash Crew", user: "drew", wager: 1800.00, coin: "usdt", payout: 1509.60, rank: 17 },
        { game: "Crash", user: "hidden", wager: 395.14, coin: "crypto", payout: 2893.41, rank: 6 },
        { game: "Sweet Bonanza", user: "hidden", wager: 18.47, coin: "cash", payout: 102.36, rank: 9 },
        { game: "Sugar Rush 1000", user: "hidden", wager: 844.92, coin: "trx", payout: 0.00, rank: 13 },
        { game: "Sugar Rush 1000", user: "Foka69", wager: 10000.00, coin: "usdt", payout: 11230.00, rank: 16 },
        { game: "Release The Bison", user: "Ginny32", wager: 600.00, coin: "usdt", payout: 2565.69, rank: 3 },
        { game: "Retro Sweets", user: "hidden", wager: 268.43, coin: "eth", payout: 1897.34, rank: 5 },
        { game: "Cash Crew", user: "hidden", wager: 740.25, coin: "usdc", payout: 0.00, rank: 7 },
        { game: "Crash", user: "hidden", wager: 503.17, coin: "crypto", payout: 3645.92, rank: 17 },
        { game: "Sweet Bonanza", user: "hidden", wager: 34.56, coin: "cash", payout: 214.78, rank: 4 },
        { game: "Aztec Twist", user: "Ginny32", wager: 575.00, coin: "usdt", payout: 0.00, rank: 12 },
        { game: "Wanted Dead Or A ...", user: "Nastydev", wager: 120.00, coin: "cash", payout: 194.52, rank: 11 },
        { game: "Infinite Blackjack", user: "nerokuyt", wager: 500.00, coin: "eth", payout: 1000.00, rank: 1 },
        { game: "Gates Of Olympus", user: "lp2dawgs", wager: 500.00, coin: "eth", payout: 111.50, rank: 8 },
        { game: "Gates Of Olympus", user: "JakeBlacktipH", wager: 100.00, coin: "crypto", payout: 161.15, rank: 15 },
        { game: "Fruit Party", user: "hidden", wager: 400.00, coin: "crypto", payout: 0.00, rank: 10 },
        { game: "Sugar Rush 1000", user: "hidden", wager: 953.21, coin: "trx", payout: 0.00, rank: 6 },
        { game: "Rooolette", user: "CE876", wager: 200.00, coin: "usdt", payout: 200.00, rank: 13 },
        { game: "Sweet Bonanza", user: "Nasshh", wager: 80.00, coin: "trx", payout: 154.68, rank: 2 },
        { game: "Blackjack 72 - Ruby", user: "slumpE", wager: 200.00, coin: "ltc", payout: 400.00, rank: 16 },
        { game: "Crash", user: "Bebe21", wager: 1979.46, coin: "cash", payout: 0.00, rank: 3 },
        { game: "Retro Sweets", user: "hidden", wager: 268.43, coin: "eth", payout: 1897.34, rank: 7 },
        { game: "Cash Crew", user: "hidden", wager: 740.25, coin: "usdc", payout: 492.10, rank: 15 },
        { game: "Mission Uncrossable", user: "MoneyHungry", wager: 5.30, coin: "crypto", payout: 0.00, rank: 14 },
        { game: "Blackjack VIP Platinum", user: "sheriff35", wager: 150.00, coin: "eth", payout: 0.00, rank: 5 },
        { game: "Cash Crew", user: "drew", wager: 750.00, coin: "usdt", payout: 180.00, rank: 11 },
        { game: "Blackjack VIP Platinum", user: "hidden", wager: 89.25, coin: "eth", payout: 1324.89, rank: 1 },
        { game: "Cash Crew", user: "drew", wager: 750.00, coin: "usdt", payout: 890.00, rank: 16 },
        { game: "Crash", user: "Jojojo10202", wager: 20.00, coin: "xrp", payout: 158.68, rank: 9 },
        { game: "Raven Rising", user: "Steve", wager: 4.00, coin: "crypto", payout: 2092.60, rank: 7 },
        { game: "Retro Sweets", user: "Ddurz", wager: 400.00, coin: "crypto", payout: 297.00, rank: 4 },
        { game: "Sweet Bonanza", user: "Dunmorejake123", wager: 600.00, coin: "cash", payout: 0.00, rank: 12 },
        { game: "Cash Crew", user: "drew", wager: 1800.00, coin: "usdt", payout: 1056.59, rank: 3 },
        { game: "Sweet Bonanza", user: "hidden", wager: 18.47, coin: "cash", payout: 102.36, rank: 15 }
    ];
    
    const rankImages = [
        beginnerImg,
        silver1Img,
        silver2Img,
        silver3Img,
        silver4Img,
        gold1Img,
        gold2Img,
        gold3Img,
        gold4Img,
        emerald1Img,
        emerald2Img,
        emerald3Img,
        ruby1Img,
        ruby2Img,
        ruby3Img,
        diamond1Img,
        diamond2Img,
        diamond3Img
    ];
    
    

    const gameImages = {
        "Mission Uncrossable": MissionUncrossable,
        "Blackjack VIP Platinum": BlackjackVIPPlatinum,
        "Cash Crew": CashCrew,
        "Crash": Crash,
        "Raven Rising": RavenRising,
        "Retro Sweets": RetroSweets,
        "Sweet Bonanza": SweetBonanza,
        "Sugar Rush 1000": SugarRush1000,
        "Roobet First Person ...": RoobetFirstPerson,
        "Release The Bison": ReleaseTheBison,
        "Aztec Twist": AztecTwist,
        "Wanted Dead Or A ...": WantedDeadOrAWild,
        "Infinite Blackjack": InfiniteBlackjack,
        "Gates Of Olympus": GatesOfOlympus,
        "Fruit Party": FruitParty,
        "Rooolette": Rooolette,
        "Blackjack 72 - Ruby": Blackjack72Ruby
    };

    const gameUrls = {
        "Mission Uncrossable": "https://roobet.com/mission-uncrossable",
        "Blackjack VIP Platinum": "https://roobet.com/game/evolution:VIPPlatinum",
        "Cash Crew": "https://roobet.com/game/hacksaw:1456",
        "Crash": "https://roobet.com/crash",
        "Raven Rising": "https://roobet.com/game/quickspin:RavenRising",
        "Retro Sweets": "https://roobet.com/game/pushgaming:RetroSweets",
        "Sweet Bonanza": "https://roobet.com/game/pragmatic:vs20fruitsw",
        "Sugar Rush 1000": "https://roobet.com/game/pragmatic:vs20sugarrushx",
        "Roobet First Person ...": "https://roobet.com/game/evolution:RoobetFirstPersonBlackjack",
        "Release The Bison": "https://roobet.com/game/pragmatic:vs20bison",
        "Aztec Twist": "https://roobet.com/game/hacksaw:1066",
        "Wanted Dead Or A ...": "https://roobet.com/game/hacksaw:1067",
        "Infinite Blackjack": "https://roobet.com/game/evolution:infinite_blackjack",
        "Gates Of Olympus": "https://roobet.com/game/pragmatic:vs20olympgate",
        "Fruit Party": "https://roobet.com/game/pragmatic:vs20fruitparty",
        "Rooolette": "https://roobet.com/game/pragmatic:232",
        "Blackjack 72 - Ruby": "https://roobet.com/game/evolution:blackjack"
    };

    const coinsSvg = {
        crypto: <BitcoinSvg className="svg-icon" />,
        usdt: <UsdtSvg className="svg-icon" />,
        usdc: <UsdcSvg className="svg-icon" />,
        eth: <EthSvg className="svg-icon" />,
        xrp: <XrpSvg className="svg-icon" />,
        trx: <TrxSvg className="svg-icon" />,
        ltc: <LtcSvg className="svg-icon" />,
        doge: <DogeSvg className="svg-icon" />,
        cash: <CashSvg className="svg-icon" />
    };

    const tableBodyRef = useRef(null);

    // Start scrolling table
    useEffect(() => {
        scrollTable()
    }, []);

    // Add event listener to disable mouse scroll on table-body
    useEffect(() => {
        const tableBody = document.querySelector('.table-body');
    
        const disableScroll = (event) => {
            event.preventDefault();
        };
    
        if (tableBody) {
            tableBody.addEventListener('wheel', disableScroll, { passive: false });
            tableBody.addEventListener('touchmove', disableScroll, { passive: false });
        }
    
        return () => {
            if (tableBody) {
                tableBody.removeEventListener('wheel', disableScroll);
                tableBody.removeEventListener('touchmove', disableScroll);
            }
        };
    }, []);

function scrollTable() {
    if (!tableBodyRef.current) {
        // If the ref is not yet assigned, exit the function
        return;
    }

    const step = 37;
    const duration = 1000;
    const steps = duration / 10;

    const initialPosition = tableBodyRef.current.scrollTop;
    const targetPosition = initialPosition - step;
    const distance = initialPosition - targetPosition;
    const delay = duration / steps;

    let currentStep = 0;

    const scrollStep = () => {
        if (tableBodyRef.current) {  // Check if tableBodyRef.current is still available
            if (currentStep < steps) {
                currentStep++;
                const easeInOutQuad = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                const newPosition = initialPosition - easeInOutQuad(currentStep / steps) * distance;
                tableBodyRef.current.scrollTop = newPosition;
                setTimeout(scrollStep, delay);
            } else {
                // If reached the top, scroll to the bottom immediately
                if (tableBodyRef.current.scrollTop === 0) {
                    tableBodyRef.current.scrollTop = tableBodyRef.current.scrollHeight;
                }
                // Restart the scrolling process after a delay
                setTimeout(scrollTable, 1000);
            }
        }
    };
    scrollStep();
}

    const getGameImage = (gameName) => {
        return gameImages[gameName] || null; // Return the image or null if not found
    };

    const getGameUrl = (gameName) => {
        return gameUrls[gameName] || null; // Return the image or null if not found
    };

    const getCoinSvg = (coin) => {
        return coinsSvg[coin] || null;
    }

    function getCurrentTime() {
        const now = new Date();
        let hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 should be displayed as 12

        // Append a leading zero to minutes if necessary
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${hours}:${formattedMinutes} ${ampm}`;
    }

    function formatBigNum(num) {
        // Check if the input is not a number or is null/undefined
        if (isNaN(num) || num === null || num === undefined) {
            return "Invalid number";
        }

        // Convert the number to a string and split it into integer and decimal parts
        const parts = num.toString().split(".");
        const integerPart = parts[0];
        const decimalPart = parts.length > 1 ? "." + parts[1] : "";

        // Add commas to the integer part
        let formattedInteger = "";
        for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
            if (j > 0 && j % 3 === 0) {
                formattedInteger = "," + formattedInteger;
            }
            formattedInteger = integerPart.charAt(i) + formattedInteger;
        }

        // Return the formatted number with decimal part
        return formattedInteger + decimalPart;
    }


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="table-container">
            <div className='table-nav'>
                <div
                    className={`table-nav-btn ${activeTab === 'All' ? 'active' : ''}`}
                    onClick={() => handleTabClick('All')}
                >
                    All
                </div>
                <div
                    className={`table-nav-btn ${activeTab === 'High Wins' ? 'active' : ''}`}
                    onClick={() => handleTabClick('High Wins')}
                >
                    High Wins
                </div>
                <div
                    className={`table-nav-btn ${activeTab === 'Lucky Wins' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Lucky Wins')}
                >
                    Lucky Wins
                </div>
            </div>
            <div className="table">
                <div className="table-head">
                    <div className='game'>GAME</div>
                    {isMoreThan600px && <div className='table-username-head'>USERNAME</div>}
                    {isMoreThan1390px && <><div>TIME</div>
                        <div className='table-alin-right'>WAGER</div>
                        <div className='table-alin-right'>MULTIPLIER</div></>}
                    <div className='table-alin-right pay-out'>PAYOUT</div>
                </div>

                <div className="table-body" ref={tableBodyRef}>
                    {data.map((row, index) => (
                        <div className="table-row" key={index}>
                            <a href={getGameUrl(row?.game)} className='game'>
                                <div className='game-img-container'>
                                    <img src={getGameImage(row?.game)} alt={row?.game} />
                                </div>
                                <div className='game-title'>{row?.game}</div>
                            </a>

                            {isMoreThan600px && <div className='table-user-name'>
                                
                                {row?.user === "hidden" ? <span className="table-hidden"><img className='rank-img' src={rankImages[row?.rank]} alt='rank'/>Hidden </span> :
                                    <a href={`https://roobet.com/?modal=profile&user=${row?.user}`} className='un-hidden'><img className='rank-img' src={rankImages[row?.rank]} alt='rank'/> {row?.user} </a>
                                }
                            </div>}

                            {isMoreThan1390px && <> <div className='bet-time'>{getCurrentTime()}</div>

                                <div className='table-alin-right wager'>${(row?.wager || 0).toFixed(2) >= 1000 ? formatBigNum((row?.wager || 0).toFixed(2)) : (row?.wager || 0).toFixed(2)}
                                    {getCoinSvg(row?.coin)}
                                </div>

                                <div className={`table-alin-right ${(row.payout / row.wager).toFixed(2) > 0.00 ? " win" : " lose"}`}>{(row?.payout / row?.wager || 0).toFixed(2)}x</div> </>}

                            <div className={`table-alin-right pay-out ${row.payout.toFixed(2) > 0.00 ? " win" : " lose"}`}>${(row?.payout || 0).toFixed(2) >= 1000 ? formatBigNum((row?.payout || 0).toFixed(2)) : (row?.payout || 0).toFixed(2)}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='bottom-smooth'>

            </div>
        </div>
    );
};

export default HistoryTable;