import React, { useEffect, useState } from 'react';
import localStorageService from '../services/local_storage';

// imgs
import beginnerImg from "../images/rank/beginner.png";
import silver1Img from "../images/rank/silver1.png";
import silver2Img from "../images/rank/silver2.png";
import silver3Img from "../images/rank/silver3.png";
import silver4Img from "../images/rank/silver4.png";
import gold1Img from "../images/rank/gold1.png";
import gold2Img from "../images/rank/gold2.png";
import gold3Img from "../images/rank/gold3.png";
import gold4Img from "../images/rank/gold4.png";
import emerald1Img from "../images/rank/emerald1.png";
import emerald2Img from "../images/rank/emerald2.png";
import emerald3Img from "../images/rank/emerald3.png";
import ruby1Img from "../images/rank/ruby1.png";
import ruby2Img from "../images/rank/ruby2.png";
import ruby3Img from "../images/rank/ruby3.png";
import diamond1Img from "../images/rank/diamond1.png";
import diamond2Img from "../images/rank/diamond2.png";
import diamond3Img from "../images/rank/diamond3.png";
import champion1Img from "../images/rank/champion1.png";
import champion2Img from "../images/rank/champion2.png";
import champion3Img from "../images/rank/champion3.png";
import legend1Img from "../images/rank/legend1.png";
import legend2Img from "../images/rank/legend2.png";
import legend3Img from "../images/rank/legend3.png";
import master1Img from "../images/rank/master1.png";
import master2Img from "../images/rank/master2.png";
import master3Img from "../images/rank/master3.png";
import grandmaster1Img from "../images/rank/grandmaster1.png";
import grandmaster2Img from "../images/rank/grandmaster2.png";
import grandmaster3Img from "../images/rank/grandmaster3.png";
import immortalImg from "../images/rank/immortal.png";

import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';

const rankImages = [
    beginnerImg,
    silver1Img,
    silver2Img,
    silver3Img,
    silver4Img,
    gold1Img,
    gold2Img,
    gold3Img,
    gold4Img,
    emerald1Img,
    emerald2Img,
    emerald3Img,
    ruby1Img,
    ruby2Img,
    ruby3Img,
    diamond1Img,
    diamond2Img,
    diamond3Img,
    champion1Img,
    champion2Img,
    champion3Img,
    legend1Img,
    legend2Img,
    legend3Img,
    master1Img,
    master2Img,
    master3Img,
    grandmaster1Img,
    grandmaster2Img,
    grandmaster3Img,
    immortalImg
];

const rankNames = [
    'Beginner',
    'Silver I', 'Silver II', 'Silver III', 'Silver IV',
    'Gold I', 'Gold II', 'Gold III', 'Gold IV',
    'Emerald I', 'Emerald II', 'Emerald III',
    'Ruby I', 'Ruby II', 'Ruby III',
    'Diamond I', 'Diamond II', 'Diamond III',
    'Champion I', 'Champion II', 'Champion III',
    'Legend I', 'Legend II', 'Legend III',
    'Master I', 'Master II', 'Master III',
    'Grandmaster I', 'Grandmaster II', 'Grandmaster III',
    'Immortal'
];

function Rank({ isOpenUserMenu }) {
    const [loggedinUser, setLoggedinUser] = useState(null)
    const [userRank, setUserRank] = useState({
        currentRank: 0,
        currentWagerAmountRequirement: 0,
        nextLevelWagerAmountRequirement: 1,
        totalWageredAmount: 0
    });

    useEffect(() => {
        const storedRank = localStorage.getItem('rank');
        const user = localStorageService.readFromLocalStorage("user")
        if (user) {
            setLoggedinUser(user)
        }
        if (storedRank) {
            setUserRank(JSON.parse(storedRank));
        }
    }, []);

    const { currentRank, totalWageredAmount, currentWagerAmountRequirement, nextLevelWagerAmountRequirement } = userRank;
    const currentRankImage = rankImages[currentRank];
    const rankType = rankNames[currentRank];

    // Calculate progress percentage
    const totalRequirementDifference = nextLevelWagerAmountRequirement - currentWagerAmountRequirement;
    const progress = totalWageredAmount - currentWagerAmountRequirement;
    const percentage = (progress / totalRequirementDifference) * 100;

    return (<>
        <div className="rank-container">
            <div className="progress-border" style={{ '--progress': `${percentage}` }}>
                <div className='img-container'>
                    <img src={currentRankImage} alt={`Rank ${currentRank}`} />
                </div>
            </div>
        </div>
        <div className='rank-type'>
            {rankType}
        </div>
        <div className='progress-bar'>
            <div className='progress-fill' style={{ width: `${percentage}%`, height: '100%' }}></div>
        </div>
        <div className='user-name-out'>
            {loggedinUser}
        </div>
        <div className='chevron'>
            <ChevronDownSvg className='svg-icon' style={{ transform: isOpenUserMenu ? 'rotate(180deg)' : 'none' }} />
        </div>
    </>
    );
}

export default Rank;
