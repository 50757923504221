import { useState, useEffect } from 'react';

//service
import localStorageService from '../services/local_storage';

//imgs
import HappyRooImg from "../images/bonus/happyroo.png";

//cmps
import MiniLoader from './MiniLoader';

function RoowardsPromotion() {
    const [loggedinUser, setLoggedinUser] = useState(null)

    useEffect(() => {
        const user = localStorageService.readFromLocalStorage("user")
        if (!user) {
            window.location.href = '/modal=auth&tab=login';
        } else {
            setLoggedinUser(user)
        }
    }, [])





    return (
        !loggedinUser ?
            <div className='loader-container'>
                <MiniLoader />
            </div>
            :
            <>
                <div className="deposit-booster-container">
                    <div className="header roowards-head">
                    </div>
                    <div className="text-description roowards">
                        <span>Congratulations {loggedinUser} !</span>
                        You have successfully claimed an extra $50 for your next monthly roowards, This will automatically be added to your rewards accumulation. The next time you go to claim your monthly roowards, you will receive this extra amount!
                    </div>
                    <div className="img-container">
                        <img height={"230px"} src={HappyRooImg} alt="Chest" />
                    </div>
                    <div className="text-description">
                        <ul>
                            <li>This bonus is available only for active users. To claim it, you have to wager at least $100 in the last month.</li>
                            <li>The bonus will be automatically added to your active account, no further action is needed.</li>
                            <li>If you didn't claim your last month's Roowards, you have to claim it, and the bonus will be added to the next month.</li>
                            <li>Remember, Roowards is an activity-based lottery. To accumulate Roowards, you have to be an active player.</li>
                            <li><a href="https://help.roobet.com/en/articles/4734412-roowards-explained">Read more about Roowards.</a></li>
                        </ul>

                    </div>
                    <div>
                        <a href='https://roobet.com' className="styled-btn">Play Now</a>
                    </div>
                    <div className="text-description terms">
                        All <a href="https://roobet.com/terms-and-conditions">Terms of Service</a> of Roobet apply to this offer.
                    </div>
                </div >
            </>
    )
}

export default RoowardsPromotion;