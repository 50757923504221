// imgs
import CoinBottom from "../images/bonus/coinBottom.avif";
import CoinLeft from "../images/bonus/coinLeft.avif";
import CoinRight from "../images/bonus/coinRight.avif";


function DepositBonus({ loggedinUser }) {

    function handdleClaim() {
            window.location.href = '/promotion=deposit-booster';
    }

    return (
        <div className="bonus-card">
            <div className="bonus-hero">
                
                <div className="coins-container">
                    <img className="coin right" src={CoinRight} alt="Bonus" />
                    <img className="coin left" src={CoinLeft} alt="Bonus" />
                    <img className="coin bottom" src={CoinBottom} alt="Bonus" />
                </div>
            </div>
            <div className="bonus-description">
                <div className="header">Deposit Booster</div>
                <div className="text-container">
                    <div>When you make a deposit, we supercharge your funds with an amazing 40% bonus.
                        Imagine depositing $100 and having an extra $40 instantly credited to your account!<br />
                        And here's the icing on the cake – you can enjoy a 40% bonus on deposits up to $10,000.</div>
                </div>
                <button className="styled-btn" onClick={handdleClaim}>Claim Bonus</button>
                <div className="terms">All <a href="https://roobet.com/terms-and-conditions">terms of service</a> apply to this offer</div>
            </div>
        </div >
    )
}

export default DepositBonus;