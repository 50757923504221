import React, { useState, useEffect } from "react";

function BetCounter() {
    const [counterValue, setCounterValue] = useState(5173554173);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCounterValue(prevValue => prevValue + 123);
        }, 1500);

        return () => clearInterval(intervalId); // Cleanup function to clear the interval
    }, []); // Empty dependency array to run the effect only once

    function formatNumber(number) {
        let numberStr = String(number).split('');
        let formattedNumber = '';
        let commaCounter = 0;

        for (let i = numberStr.length - 1; i >= 0; i--) {
            formattedNumber = numberStr[i] + formattedNumber;
            commaCounter++;
            if (commaCounter % 3 === 0 && i !== 0) {
                formattedNumber = ',' + formattedNumber;
            }
        }

        return formattedNumber;
    }

    return (
        <div className="bet-counter">
            <h2>Total Bets Placed</h2>
            <p>{formatNumber(counterValue)}</p>
        </div>
    );
}

export default BetCounter;
