
function RoowardBonus({ loggedinUser }) {

    function handdleClaim() {
        if (loggedinUser) {
            window.location.href = '/roowards-bonus';
        } else {
            window.location.href = '/modal=auth&tab=login';
        }
    }

    return (
        <div className="bonus-card">
            <div className="bonus-hero roowards">

            </div>
            <div className="bonus-description">
                <div className="header">Extra Roowards</div>
                <div className="text-container">
                    <div>Claim an extra $50 on your next monthly Roowards!
                        Roobet values you! Enjoy an additional $50 bonus in your upcoming monthly Roowards as a gesture of our ongoing commitment.
                        Your bonus will be seamlessly added to your Roowards account calculation.
                    </div>
                </div>
                <button className="styled-btn" onClick={handdleClaim}>Claim Bonus</button>
                <div className="terms">All <a href="https://roobet.com/terms-and-conditions">terms of service</a> apply to this offer</div>
            </div>
        </div >
    )
}

export default RoowardBonus;