//cmps
import Links from './Links';
import AcceptedCurrencies from './AcceptedCurrencies';
import AwardsLicenses from './AwardsLicenses';
import Partners from './Partners';
import Copyright from './Copyright';

function Footer() {

    return (
        <div className="footer">
            <div className="footer-hr"></div>

            <Links />

            <AcceptedCurrencies />

            <div className="footer-hr"></div>

            <AwardsLicenses />

            <Partners />

            <div className="footer-hr"></div>

            <Copyright />
        </div >
    )
}

export default Footer;