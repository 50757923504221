// Define the localStorage_service module
const localStorageService = (function() {
  
    // Function to add an item to local storage
    function addToLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  
    // Function to read an item from local storage
    function readFromLocalStorage(key) {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
  
    // Function to delete an item from local storage
    function deleteFromLocalStorage(key) {
      localStorage.removeItem(key);
    }
  
    // Function to edit an item in local storage
    function editLocalStorage(key, newValue) {
      const existingValue = readFromLocalStorage(key);
      if (existingValue !== null) {
        addToLocalStorage(key, newValue);
      } else {
        addToLocalStorage(key, newValue);
      }
    }
  
    // Export functions
    return {
      addToLocalStorage,
      readFromLocalStorage,
      deleteFromLocalStorage,
      editLocalStorage
    };
  
  })();
  
  // Export the localStorage_service module
  export default localStorageService;
  