function HeroLoggedin() {

    return (
        <div className="hero-loggedin">
            <div>
                <div>WELCOME TO ROOBET</div>
                <a href="https://roobet.com/" className="styled-btn-primery" style={{ width: "80%"}}>Bet Now</a>
            </div>
        </div >
    )
}

export default HeroLoggedin;