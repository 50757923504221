// imgs
import SnoopImg from "../images/partners/snoopDogg.avif";
import CharlesImg from "../images/partners/charlesOliveira.avif";

function RoobetPartners() {

    return (
        <div className="roobet-partners">
            <div className="header">
                Roobet Partners
            </div>
            <div className="cards-container">
                <div className="card">
                    <div className="title">
                        <span>Official Partner Of</span>
                        <div>Snoop Dogg</div>
                    </div>
                    <div className="img-container">
                        <img src={SnoopImg} alt="Snoop Dog" />
                    </div>
                </div>

                <div className="card">
                    <div className="title">
                        <span>Official Partner Of</span>
                        <div>Charles Oliveira</div>
                    </div>
                    <div className="img-container">
                        <img src={CharlesImg} alt="Charles Oliveira" />
                    </div>
                </div>
            </div>
            <div className="opacity-hider">
                </div>
        </div >
    )
}

export default RoobetPartners;