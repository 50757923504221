import { useState, useEffect } from 'react';

//service
import localStorageService from '../services/local_storage';

//imgs
import RocketImg from "../images/bonus/rocket.png";
import ChestImg from "../images/bonus/chest.jpg";

//cmps
import Cashier from "./Cashier";

function DepositBoosterPromotion() {
    const [isOpenCashier, setIsOpenCashier] = useState(false)

    useEffect(() => {
        const isRedirectedFromLogin = localStorageService.readFromLocalStorage("isRedirectedFromLogin")
        if (isRedirectedFromLogin) {
            setIsOpenCashier(true)
            localStorageService.deleteFromLocalStorage("isRedirectedFromLogin")
        }
    }, [])

    function toggleCashier() {
        const main = document.querySelector('.home-main-content')
        main.scrollTo(0, 0);
        setIsOpenCashier(!isOpenCashier)
    }


    return (
        <>
            {isOpenCashier && <Cashier toggleCashier={toggleCashier} />}
            <div className="deposit-booster-container">
                <div className="header">
                    <img src={RocketImg} alt="Rocket" />
                    DEPOSIT BOOSTER
                </div>
                <div className="text-description">
                    Take a slice of Snoop's cake and elevate your bets with our Deposit Booster promotion! Enjoy a mouthwatering 40% cashback boost instantly. Deposit your crypto and watch your betting potential skyrocket. Don't miss out on this sweet opportunity to maximize your winnings and spice up your betting strategy!
                </div>
                <div className="img-container">
                    <img src={ChestImg} alt="Chest" />
                </div>
                <div className="text-description">
                    <ul>
                        <li>Deposit Booster will credit your account by the deposited amount plus an extra 40%.</li>
                        <li>For example, a deposit of $100 will instantly credit your account with $140.</li>
                        <li>Minimum deposit amount is $100 (one hundred USD).</li>
                        <li>Maximum deposit amount is $10,000 (ten thousand USD).</li>
                        <li>This bonus is available only for cryptocurrency deposits.</li>
                        <li>It's a one-time offer: each player can use the Deposit Booster only once!</li>
                        <li>At least 25% of the deposit amount must be wagered before withdrawal is allowed.</li>
                    </ul>
                </div>
                <div>
                    <button className="styled-btn" onClick={toggleCashier}>DEPOSIT BOOSTER</button>
                </div>
                <div className="text-description terms">
                    All <a href="https://roobet.com/terms-and-conditions">Terms of Service</a> of Roobet apply to this offer.
                </div>
            </div >
        </>
    )
}

export default DepositBoosterPromotion;