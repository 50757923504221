import logoImg from "../images/logo.png";

function Copyright() {

    return (
        <div className="copyright">
            <img src={logoImg} alt="logo" />
            <div>
                Roobet.com is a brand name of Raw Entertainment B.V, Reg No 157205, having its registered address at Korporaalweg 10, Curacao, licensed to conduct online gaming operations by the Government of Curacao under license 8048/JAZ. Raw Entertainment Ltd, Reg No HE421735, having its registered address at Voukourestiou, 25, Neptune House, 1st Floor, Flat/Office11, Zakaki, 3045, Limassol, Cyprus is a company of the Roobet group that acts as a paying and operational agent on behalf of Raw Entertainment B.V. 18+ to play.
            </div>
        </div >
    )
}

export default Copyright;