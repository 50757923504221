import { useState } from 'react';

import { ReactComponent as SearchSvg } from '../svg/search.svg';
import { ReactComponent as CloseSvg } from '../svg/close.svg';
import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';
import { ReactComponent as LobbySvg } from '../svg/lobby.svg';
import { ReactComponent as GamesSvg } from '../svg/games.svg';
import { ReactComponent as SlotsSvg } from '../svg/slots.svg';
import { ReactComponent as CasinoSvg } from '../svg/casino.svg';
import { ReactComponent as GameShowsSvg } from '../svg/games-shows.svg';
import { ReactComponent as PopularSvg } from '../svg/popular.svg';

//games imgs
import sweetBonanzaImg from "../images/games/sweet-bonanza.avif";
import fruitPartyImg from "../images/games/fruit-party.avif";
import gatesOfOlympus1000Img from "../images/games/gates-of-olympus1000.avif";
import rooBonanzaImg from "../images/games/roo-bonanza.avif";
import gatesOfOlympusImg from "../images/games/gates-of-olympus.avif";
import madameDestinyMegawaysImg from "../images/games/madame-destiny-megaways.avif";
import wantedDeadOrAwildImg from "../images/games/wanted-dead-or-a-wild.avif";
import sugarRush1000Img from "../images/games/sugar-rush1000.avif";
import sugarRushImg from "../images/games/sugar-rush.avif";
import lionsMegawaysImg from "../images/games/5lions-megaways.avif";
import sweetBonanzaXmasImg from "../images/games/sweet-bonanza-xmas.avif";
import starlightPrincessImg from "../images/games/starlight-princess.avif";
import oxygenImg from "../images/games/oxygen.avif";
import stromForgedImg from "../images/games/strom-forged.avif";
import tombstoneNoMercyImg from "../images/games/tombstone-no-mercy.avif";


function NavSearch({ toggleSearch }) {
    const [searchTerm, setSearchTerm] = useState('');

    const gamesData = [
        { gameName: "Sweet Bonanza", img: sweetBonanzaImg, link: "https://roobet.com/game/pragmatic:vs20fruitsw", type: "Pragmatic Play" },
        { gameName: "Fruit Party", img: fruitPartyImg, link: "https://roobet.com/game/pragmatic:vs20fruitparty", type: "Pragmatic Play" },
        { gameName: "Gates of Olympus 1000", img: gatesOfOlympus1000Img, link: "https://roobet.com/game/pragmatic:vs20olympx", type: "Pragmatic Play" },
        { gameName: "Roo Bonanza", img: rooBonanzaImg, link: "https://roobet.com/game/pragmatic:vs20fruitswroo", type: "Pragmatic Play" },
        { gameName: "Gates of Olympus", img: gatesOfOlympusImg, link: "https://roobet.com/game/pragmatic:vs20olympgate", type: "Pragmatic Play" },
        { gameName: "Madame Destiny Megaways", img: madameDestinyMegawaysImg, link: "https://roobet.com/game/pragmatic:vswaysmadame", type: "Pragmatic Play" },
        { gameName: "Wanted Dead Or A Wild", img: wantedDeadOrAwildImg, link: "https://roobet.com/game/hacksaw:1067", type: "Hacksaw Gaming" },
        { gameName: "Sugar Rush 1000", img: sugarRush1000Img, link: "https://roobet.com/game/pragmatic:vs20sugarrushx", type: "Pragmatic Play" },
        { gameName: "Sugar Rush", img: sugarRushImg, link: "https://roobet.com/game/pragmatic:vs20sugarrush", type: "Pragmatic Play" },
        { gameName: "Lions Megaways", img: lionsMegawaysImg, link: "https://roobet.com/game/pragmatic:vswayslions", type: "Pragmatic Play" },
        { gameName: "Sweet Bonanza Xmas", img: sweetBonanzaXmasImg, link: "https://roobet.com/game/pragmatic:vs20sbxmas", type: "Pragmatic Play" },
        { gameName: "Starlight Princess", img: starlightPrincessImg, link: "https://roobet.com/game/pragmatic:vs20starlight", type: "Pragmatic Play" },
        { gameName: "Oxygen", img: oxygenImg, link: "https://roobet.com/game/elk:oXygen", type: "Elk" },
        { gameName: "Storm Forged", img: stromForgedImg, link: "https://roobet.com/game/hacksaw:1243", type: "Hacksaw Gaming" },
        { gameName: "Tombstone No Mercy", img: tombstoneNoMercyImg, link: "https://roobet.com/game/nolimit:TombstoneNoMercy", type: "Nolimit City" },
    ];

    const handleInputChange = (event) => {
        event.preventDefault()
        setSearchTerm(event.target.value);
    };

    const filteredGames = gamesData.filter((game) =>
        game.gameName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    function handleScrollToRight() {
        const container = document.querySelector('.nav-search-category-container');
        const leftBtn = document.querySelector('.nav-search-category-scroller-left')
        const rightBtn = document.querySelector('.nav-search-category-scroller')

        container.scrollLeft += 600; // Adjust the value as needed
        leftBtn.style.display = 'flex'
        rightBtn.style.display = 'none'
    }

    function handleScrollToLeft() {
        const container = document.querySelector('.nav-search-category-container');
        const leftBtn = document.querySelector('.nav-search-category-scroller-left')
        const rightBtn = document.querySelector('.nav-search-category-scroller')

        container.scrollLeft -= 600; // Adjust the value as needed
        leftBtn.style.display = 'none'
        rightBtn.style.display = 'flex'
    }

    const GameCard = ({ gameName, img, link ,type}) => (
        <div className='nav-search-game-card'>
            <a href={link} className='nav-search-game-card-img-container'>
                <img src={img} alt={gameName} />
            </a>
            <div>{type}</div>
        </div>
    );

    return (
        <div className="nav-search">
            <div className="nav-search-top">
                <div className="nav-search-top-input-container">
                    <div>
                        <SearchSvg className="svg-icon" />
                    </div>
                    <input
                        type="text"
                        placeholder='Search All Games'
                        value={searchTerm}
                        onChange={handleInputChange}
                    />
                </div>
                <button className='close-btn' onClick={() => { toggleSearch() }}>
                    <CloseSvg className="svg-icon" />
                </button>
            </div>

            <div className='nav-search-dropdown'>
                <div>
                    <p>Provider:</p>
                    <span>All</span>
                </div>
                <ChevronDownSvg className="svg-icon" />
            </div>

            <div className='nav-search-category-container'>
                <div className='nav-search-category-scroller' onClick={() => { handleScrollToRight() }}>
                    <ChevronDownSvg className="svg-icon" />
                </div>

                <div className='nav-search-category-scroller-left' onClick={() => { handleScrollToLeft() }}>
                    <ChevronDownSvg className="svg-icon" />
                </div>

                <a href="https://roobet.com/casino" className="nav-search-category-item">
                    <LobbySvg className="svg-icon" />
                    <p>Lobby</p>
                </a>

                <a href="https://roobet.com/casino?category=roobet-games" className="nav-search-category-item">
                    <GamesSvg className="svg-icon" />
                    <p>Roobet</p>
                </a>

                <a href="https://roobet.com/casino?category=slots" className="nav-search-category-item">
                    <SlotsSvg className="svg-icon" />
                    <p>Slots</p>
                </a>

                <a href="https://roobet.com/casino?category=livecasino" className="nav-search-category-item">
                    <CasinoSvg className="svg-icon" />
                    <p>Casino</p>
                </a>

                <a href="https://roobet.com/casino?category=gameshows" className="nav-search-category-item">
                    <GameShowsSvg className="svg-icon" />
                    <p>Game Shows</p>
                </a>

                <a href="https://roobet.com/casino?category=popular" className="nav-search-category-item">
                    <PopularSvg className="svg-icon" />
                    <p>Popular</p>
                </a>

            </div>

            <div className='nav-search-games-container'>
                {filteredGames.map((game, index) => (
                    <GameCard
                        key={index}
                        gameName={game.gameName}
                        img={game.img}
                        link={game.link}
                        type={game.type}
                    />
                ))}
            </div>
        </div >
    )
}

export default NavSearch;