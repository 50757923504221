import { ReactComponent as BitcoinSvg } from '../svg/currencies/bitcoin.svg';
import { ReactComponent as UsdtSvg } from '../svg/currencies/usdt.svg';
import { ReactComponent as UsdcSvg } from '../svg/currencies/usdc.svg';
import { ReactComponent as EthSvg } from '../svg/currencies/eth.svg';
import { ReactComponent as XrpSvg } from '../svg/currencies/xrp.svg';
import { ReactComponent as TrxSvg } from '../svg/currencies/trx.svg';
import { ReactComponent as LtcSvg } from '../svg/currencies/ltc.svg';
import { ReactComponent as DogeSvg } from '../svg/currencies/doge.svg';
import { ReactComponent as CashSvg } from '../svg/currencies/cash.svg';

function AcceptedCurrencies() {
    const coins = [
        { currency: "Bitcoin (BTC)", icon: <BitcoinSvg className="svg-icon" /> },
        { currency: "Tether (USDT)", icon: <UsdtSvg className="svg-icon" /> },
        { currency: "USD Coin (USDC)", icon: <UsdcSvg className="svg-icon" /> },
        { currency: "Ethereum (ETH)", icon: <EthSvg className="svg-icon" /> },
        { currency: "XRP (XRP)", icon: <XrpSvg className="svg-icon" /> },
        { currency: "TRON (TRX)", icon: <TrxSvg className="svg-icon" /> },
        { currency: "Litecoin (LTC)", icon: <LtcSvg className="svg-icon" /> },
        { currency: "Dogecoin (DOGE)", icon: <DogeSvg className="svg-icon" /> },
        { currency: "Cash", icon: <CashSvg className="svg-icon" /> }
    ];

    return (
        <div className="accepted-currencies">
            <h2>Accepted Currencies</h2>
            {coins.map((coin, index) => (
                <div className='currencie-container' key={index}>
                    {coin.icon}
                    <p>{coin.currency}</p>
                </div>
            ))}
        </div>
    );
}

export default AcceptedCurrencies;
