import React from 'react';

const Unknown = () => {
    document.body.style.background = '#fff'
    document.body.style.color = '#000'
    document.body.style.fontSize = '14px'
    document.body.style.padding = '20px'
    return (
        <div >
            <h2>This page doesn't seem to exist.</h2>
        </div>
    );
};

export default Unknown;
