
//SVG
import { ReactComponent as GoogleSvg } from '../svg/google.svg';
import { ReactComponent as SteamSvg } from '../svg/steam.svg';
import { ReactComponent as MetamaskSvg } from '../svg/metamask.svg';

function Hero() {
    return (
        <div className="hero-container">
            <div className="hero-img">
            </div>
            <div className="hero-register-container">
                <h2>Welcome To Roobet</h2>
                <h3>Hop In</h3>
                <div>
                    <a href='https://roobet.com/?modal=auth&tab=register'
                        className="styled-btn">Register Now</a>
                </div>
                <div className="styled-header">Or continue with</div>
                <div className="social-btns-continer">
                    <div className='social-btn-container'>
                        <GoogleSvg className="svg-icon" />
                        <span className='social-title'>Google</span>
                    </div>
                    <div className='social-btn-container'>
                        <SteamSvg className="svg-icon" />
                        <span className='social-title'>Steam</span>
                    </div>
                    <div className='social-btn-container'>
                        <MetamaskSvg className="svg-icon" />
                        <span className='social-title'>Metamask</span>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Hero;